import Layout from '../components/layout/Layout'
import ReCAPTCHA from 'react-google-recaptcha'
import React, { useState } from 'react'
import FullWidthCaptionedTitle from '../components/FullWidthCaptionedTitle'
import TextInput, { defaultValidate, validateEmail } from '../components/inputs/TextInput/TextInput'
import RobotInput from '../components/inputs/RobotInput/RobotInput'
import TextareaInput from '../components/inputs/TextareaInput/TextareaInput'
import Modal from '../components/Modal'
import SEO from '../components/SEO'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const modalData = {
  success: {
    title: 'Thank you for filling the form',
    text: 'We will be in touch with you shortly!'
  },
  fail: {
    title: 'Problem submitting',
    text: 'Please try again later'
  }
}
const defaultFormState = {
  'form-name': 'contact',
  message: '',
  'last-name': '',
  'first-name': '',
  email: '',
  tel: ''
}

const defaultValidState = {
  'first-name': true,
  'last-name': true,
  email: true,
  tel: true,
  message: true
}

const ContactUs = () => {
  const [modalD, setModalD] = useState({ ...modalData.success })
  const [open, setOpen] = useState(false)
  const [fromValidState, setFormValidState] = useState<Record<string, boolean>>({
    ...defaultValidState
  })
  const [formState, setFormState] = useState<Record<string, string>>({
    'form-name': 'contact',
    ...defaultFormState
  })
  const updateStateValue = (name: string, value: string) => {
    setFormValidState({ ...fromValidState, [name]: true })
    setFormState({ ...formState, [name]: value })
  }
  return (
    <>
      <Modal open={open} setOpen={setOpen} {...modalD} />
      <Layout>
        <SEO
          title="Contact us"
          description="We are looking forward to help you getting started with eduScrum!"
        />
        <FullWidthCaptionedTitle
          id="contact-us-header"
          title="Would you like to know more?"
          caption="Contact us"
          text="Are you curious about something else? Ask us anything! Feel free to send us a message. We are looking forward to getting in contact with you!!"
        />
        <section id="contact-form" className="container mx-auto pt-10 pb-24 text-center">
          <form
            className="mx-auto w-full max-w-4xl text-left"
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <RobotInput onChange={updateStateValue} />
            <div className="flex flex-wrap mb-6">
              <TextInput
                id="first-name"
                value={formState['first-name']}
                label="First name"
                onChange={updateStateValue}
                isValid={fromValidState['first-name']}
              />
              <TextInput
                id="last-name"
                value={formState['last-name']}
                label="Last name"
                onChange={updateStateValue}
                isValid={fromValidState['last-name']}
              />
            </div>
            <div className="flex flex-wrap mb-6">
              <TextInput
                id="email"
                value={formState['email']}
                label="Email address"
                onChange={updateStateValue}
                type="email"
                isValid={fromValidState.email}
                errorMessage="Has to be a valid email address"
              />
              <TextInput
                id="tel"
                value={formState['tel']}
                label="Phone number"
                onChange={updateStateValue}
                type="tel"
                isValid={fromValidState.tel}
              />
            </div>
            <div className="flex flex-wrap mb-6">
              <TextareaInput
                id="message"
                value={formState['message']}
                label="Message"
                onChange={updateStateValue}
                isValid={fromValidState.message}
              />
              <div className="mx-3 mt-4">
                <ReCAPTCHA
                  sitekey="6LempfEcAAAAABqYrNuHnt3iywAW-Po9rRGNwV8L"
                  onChange={(value: string) => {
                    updateStateValue('g-recaptcha-response', value)
                  }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center">
              <div className="m-4 w-90 md:w-1/3 cursor-pointer">
                <input
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault()
                    let isValid = true
                    const newValidState = { ...fromValidState }
                    Object.keys(defaultValidState).forEach(async (current: string) => {
                      const isValueValid = defaultValidate(formState[current])
                      newValidState[current] = isValueValid
                      isValid = isValid && isValueValid
                    })
                    const isEmailValid = validateEmail(formState.email)
                    newValidState.email = isEmailValid
                    isValid = isValid && isEmailValid
                    setFormValidState(newValidState)
                    if (!isValid) return
                    fetch('/', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                      body: encode({ 'form-name': 'contact', ...formState })
                    })
                      .then((res) => {
                        if (res.status !== 200) {
                          throw new Error('Problem submitting')
                        }
                        setModalD({ ...modalData.success })
                        setOpen(true)
                        setFormValidState({ ...defaultValidState })
                        setFormState({ ...defaultFormState })
                      })
                      .catch(() => {
                        setModalD({ ...modalData.fail })
                        setOpen(true)
                      })
                  }}
                  className={`w-full p-4  cursor-pointer
                bg-gradient-to-br from-primary-lighter to-primary-darker
              hover:bg-primary-darker
                rounded-full
              text-white
                font-semibold
                font-body `}
                />
              </div>
            </div>
          </form>
        </section>
      </Layout>
    </>
  )
}

export default ContactUs
